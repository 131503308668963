

























































































































































































































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import RulesMixin from "@/mixins/RulesMixin";
import { ReferenceItem } from "@/services/types";
import ReadOnlyInput from "@/components/form/input/ReadOnlyInput.vue";
import { ProjectType } from "@/entity/Project";
import Company from "@/entity/Company";
import GlobalService from "@/services/GlobalService";
import { Address } from "@/entity/Contact";
import { ContactFromApiPayload } from "../contact-from-search-api/types";
import ContactSearchApiForm from "@/components/contact-from-search-api/ContactSearchApiForm.vue";
import Industry, { ContactIndustry } from "@/entity/Industry";
import { State } from "vuex-class";
import { differenceWith, isEqual } from "lodash";

@Component({
  components: {
    ReadOnlyInput,
    ContactSearchApiForm,
  },
})
export default class ProjectFormCompanyInformation extends Mixins(RulesMixin) {
  @State("countries", { namespace: "selectOptions" })
  countries!: ReferenceItem[];

  @Prop({ type: String, required: true }) projectType!: ProjectType;

  @PropSync("projectContactId", { type: Number })
  projectContactIdLocal!: number;
  @PropSync("teaserUrl", { type: String })
  teaserUrlLocal!: string;
  @PropSync("teaserTitle", { type: String })
  teaserTitleLocal!: string;

  @Prop({ type: Array, required: true }) regions!: ReferenceItem[];
  @Prop() companies!: [Company];
  @Prop() setCompanyData!: (company: any) => void;
  @Prop({ type: Object }) projectContact!: Company | null;
  @Prop({ type: Boolean, default: false }) isEditing!: boolean;

  @PropSync("name", { type: String }) nameLocal!: string;
  @PropSync("url", { type: String }) urlLocal!: string;
  @PropSync("email", { type: String }) emailLocal!: string;
  @PropSync("regionId", { type: String }) regionIdLocal!: string;
  @PropSync("address") addressLocal!: Address;
  @PropSync("postcode", { type: String }) postcodeLocal!: string;
  @PropSync("city", { type: String }) cityLocal!: string;
  @PropSync("country", { type: String }) countryLocal!: string;
  @PropSync("companyTypeId", { type: String }) companyTypeIdLocal!: string;
  @PropSync("employees", { type: String }) employeesLocal!: string;
  @PropSync("fullTimeEquivalent", { type: String })
  fullTimeEquivalentLocal!: string;
  @PropSync("phoneMobile", { type: String }) phoneMobileLocal!: string;
  @PropSync("phoneFixed", { type: String }) phoneFixedLocal!: string;
  @PropSync("branches", { type: Array })
  branchesLocal!: ContactIndustry[];
  @PropSync("subBranchIds", { type: Array }) subBranchIdsLocal!: number[];
  @Prop() resetCompany!: () => void;

  @State("industries", { namespace: "selectOptions" })
  industries!: Industry[];
  @State("subIndustries", { namespace: "selectOptions" })
  subIndustries!: ReferenceItem[];

  ProjectType = ProjectType;
  companyTypes: ReferenceItem[] = [];
  company: Partial<Company> = {};
  fetchingCompanies = false;
  checked = false;
  searchApiDialogOpened = false;

  reset() {
    this.resetCompany();
    this.projectContactIdLocal = 0;
  }

  resetField(name: string) {
    (this.$refs[name] as any).lazySearch = "";
  }

  onManualBranchChange(value: Industry[]): void {
    this.resetField("branchField");

    const newValue: any[] = value;
    if (newValue.length > this.branchesLocal.length) {
      const addedItem = differenceWith(
        newValue,
        this.branchesLocal,
        isEqual
      )[0];
      const newItem: ContactIndustry = {
        id: addedItem.id as number,
        name: addedItem.name,
        manual: true,
      };
      const indexOfBranch = newValue.findIndex(
        (branch) => newItem.id === branch.id
      );
      if (indexOfBranch !== -1) {
        newValue[indexOfBranch] = newItem;
      }
    }

    this.branchesLocal = newValue;
  }

  openSearchApiDialog(): void {
    this.searchApiDialogOpened = true;
  }

  onContactFromApiSelect({ contact }: ContactFromApiPayload): void {
    this.$nextTick(() => {
      this.setCompanyData({
        ...contact,
      });
      this.searchApiDialogOpened = false;
      this.checked = true;
    });
  }

  async created() {
    [this.companyTypes] = await Promise.all([GlobalService.getCompanyTypes()]);
  }
}
